/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { RenderSections } from '@components/UI/Common/SectionCommonUI';
import PlannedGiftsOtherWaysToGive from '@sections/donation/PlannedGiftsOtherWaysToGive';

/* Services */

/**
 * Renders the Yoga Common UI component.
 *
 * @param {Array} pageBody - Content from api.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @returns {ReactElement} YogaCommonUI Component.
 */

const YogaCommonUI = ({ pageBody, region, lang, pageConfig, pageName, pageData, disableButton, FYDdate, csrData }) => {
  let plannedGiftTabData = [];
  return (
    <Box
      as="section"
      id="centers-section"
      className="container-article btn-center"
    >
      {pageBody && pageBody.length
        ? pageBody.map((section, i) => {
          if (
            section.sectionId === 'planned-gifts' ||
            section.sectionId === 'other-ways-to-give' ||
            section.sectionId === 'new-ways-to-give'
          ) {
            plannedGiftTabData.push({
              id: section.sectionId,
              data: section.sectionContent,
            });
            return (
              plannedGiftTabData?.length < 2 && (
                <PlannedGiftsOtherWaysToGive
                  plannedGiftTabData={plannedGiftTabData}
                  region={region}
                  lang={lang}
                />
              )
            );
          } else if (section.sectionId === 'tab-index-section') {
            return <></>;
          }
          return (
            <>
              <RenderSections
                disableButton={disableButton}
                sectionContent={section.sectionContent}
                pageBody={pageBody}
                pageConfig={pageConfig}
                section={section}
                region={region}
                lang={lang}
                pType="yoga"
                key={i}
                FYDdate={FYDdate}
                csrData={csrData}
              />
            </>
          );
        })
        : null}
    </Box>
  );
};

export default YogaCommonUI;
